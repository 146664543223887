<template>
  <div class="page-anniversary_lottery">
    <headeBar left-arrow @click-left="newAppBack" title="刮卡抽奖"></headeBar>
    <div class="jb"></div>
    <div class="headbg">
      <div class="line1">您有{{ count }}次抽奖机会</div>
      <div class="line2">抽奖时间截止：3月7日 10:00</div>
    </div>
    <div class="basebg pdt">
      <div class="banner-rule">
        <img src="https://img.chaolu.com.cn/ACT/spring2024/banner_rule22.png" alt="" />
      </div>
      <template v-if="hasData">
        <div class="tips">*奖品图仅供参考，以实物为准</div>
        <div class="goods-list">
          <div class="goods-model" v-for="(item, index) in list" :key="index">
            <div class="sec1">
              <div>下单时间 {{ item.payTime }} </div>
              <div>实付金额：{{ item.payAmount }}元</div>
            </div>
            <div class="sec2">
              <div class="prizes-img">
                <img :src="item.prizePicture" v-if="item.prizePicture || item.isUse == 1" />
                <div class="notgame" v-else>未开奖</div>
              </div>
              <div class="pub_onefull prizes-info">
                <div class="line1 ellipsis" v-if="item.prizeName || item.isUse == 1">{{ item.prizeName }}</div>
                <div class="line2" :class="{ tl: item.isUse != 1 }">{{ item.orderSubject }}</div>
              </div>
              <div class="rb">
                <!-- isUse	是否抽奖(0:未抽奖;1:已抽奖 ;3:活动已经结束 ) -->
                <!-- claimStatus	领取状态 (NOT_SEND:待领取；SENDED:已领取)-->
                <img v-if="item.isUse == 0" class="button" @click="open(item)" src="https://img.chaolu.com.cn/ACT/anniversary-2023/lottery/sub-open.png" />
                <template v-else-if="item.isUse == 1">
                  <img v-if="item.claimStatus == 'SENDED'" class="button" @click="showGet(item)" src="https://img.chaolu.com.cn/ACT/anniversary-2023/lottery/sub-hasget.png" />
                  <img v-else class="button" @click="showGet(item)" src="https://img.chaolu.com.cn/ACT/anniversary-2023/lottery/sub-get.png" />
                </template>
                <img v-else class="end" src="https://img.chaolu.com.cn/ACT/anniversary-2023/lottery/end.png" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="nochance">
        <img src="https://img.chaolu.com.cn/ACT/spring2024/none.png" class="noneimg" />
        <div>暂无抽奖机会，快去主会场去选购商品吧~</div>
      </div>
    </div>
    <div class="basebg">
      <div class="ci"></div>
      <div class="bd">
        <div class="tips2">*奖品图仅供参考，最终以实物为准</div>
        <img src="https://img.chaolu.com.cn/ACT/spring2024/prize.png" alt="" />
      </div>
    </div>
    <div class="basebg sm">
      <div class="ci" style="background-image: url('https://img.chaolu.com.cn/ACT/spring2024/rulebg.png');"></div>
      <div class="rule">
        <div class="rule-detail">
          <div style="text-align: center;">—— 购买超鹿春醒任意活动商品，即可参与抽奖 ——</div>
          <div>1、抽奖时间截止至3月7日 10:00，逾期将视为自动放弃抽奖机会。</div>
          <div>2、抽中的奖品将以兑换券的形式放入您的账户中；抽中“戴森吹风机”、“华为运动手表GT4”工作人员将在10个工作日内联系您。</div>
          <div>3、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。</div>
        </div>
      </div>
    </div>
    <div class="body-end">已经滑到底部啦</div>

    <van-popup v-model="showGame" @opened="openedFun" position="bottom" safe-area-inset-bottom class="popgame">
      <div class="pop-head">
        开始抽奖
        <van-icon name="cross" class="pop-rule-close" color="#fff" @click="showGame = false" />
      </div>
      <div class="popup-box">
        <div class="canvas-box" ref="canvasBox" v-if="showGame">
          <div class="game-title">{{ gameStatus == 'end' ? '请查收' : '刮开试试手气' }}</div>
          <div class="canvas-bg">
            <div class="award-info" v-show="awardInfo.prizeName">
              <div class="f1">恭喜您抽中</div>
              <div class="f2 ellipsis">{{ awardInfo.prizeName }}</div>
              <img class="f3" alt :src="awardInfo.picUrl" v-if="awardInfo.picUrl" />
            </div>
            <canvas ref="canvas" />
          </div>
        </div>
        <div class="close-game f32 fw6" :class="{ showEnd: gameStatus == 'end' }" @click="close">
          {{ awardInfo.participateId ? '领取' : '确认' }}
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showCollectionRecords" position="bottom" safe-area-inset-bottom class="pop">
      <div class="pop-title">
        <span class="ts">领取记录</span>
        <van-icon name="cross" class="pop-close" color="#fff" @click="showCollectionRecords = false" />
      </div>
      <div class="collection-records">
        <div class="mode1">奖品将以邮寄方式发放</div>
        <div class="jg"></div>
        <div class="records-box">
          <div class="records-model">
            <div class="records-img">
              <img :src="getObj.prizePicture || getObj.picUrl" alt="" />
            </div>
            <div class="records-info">
              <div class="records-name">{{ getObj.prizeName }}</div>
            </div>
          </div>
          <div class="records-model">
            <add-address @change="addressChange" color="#FFDE00"></add-address>
          </div>
        </div>

      </div>
      <div class="close-game f32 fw6 showEnd" @click="toGet">
        确认领取
      </div>
    </van-popup>
  </div>
</template>
<script>
import { newAppBack, initBack } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import addAddress from '../marketing/components/address'
import userMixin from '@/mixin/userMixin';
import Scratchcard from "@/lib/scratchcard";
import wx from 'weixin-js-sdk';
import { getFormatDate } from '@/lib/utils';
const scratch = require("@/assets/img/scratch_prize.png");
const activityNo = '2024springLuckDraw_1';

const webUrl = `${window.location.origin}/#/superdeer-activity/spring-awakening-2024/index`

export default {
  components: {
    headeBar, addAddress
  },
  mixins: [userMixin],
  data() {
    return {
      showGame: false,
      showCollectionRecords: false,
      shareParams: {
        title: '春醒刮卡抽奖',
        miniImage:
          '',
        path: webUrl + '&userId=1&token=1',
        miniProgramType:
          window.location.origin.indexOf('web.chaolu.com') > -1
            ? '0'
            : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      list: [],
      awardInfo: {},//奖品
      count: 0,
      hasData: true,
      gameStatus: '',
      getObj: {},

      address: {}, // 收货地址
    }
  },
  methods: {
    newAppBack,
    close() {
      this.showGame = false;
      if(this.awardInfo.participateId){
        this.showGet(this.awardInfo)
      }
    },
    open(item) {
      if (item.isUse !== 0) {
        return;
      }
      this.award = '';
      this.serialNumber = item.serialNumber;
      this.gameStatus = 'init';
      this.awardInfo = {};
      this.showGame = true;
      this.itemActivityNo = item.activityNo
    },
    showGet(item) {
      if (item.participateId) {
        this.getObj = item;
        this.showCollectionRecords = true;
      } else {
        this.$toast('已发放至您的账户');
      }
    },
    toGet() {
      if (this.address.addressDetail) {
        this.getMailReward()
      } else {
        this.$toast('请填写邮寄地址')
      }
    },
    getMailReward() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios.post(`${this.baseURLApp}/greatPromotion88/awardingByAddUserAddress`, {
        activityNo,
        token: this.token,
        userId: this.userId,
        participateId: this.awardInfo.participateId || this.getObj.participateId,
        ...this.address
      }).then(res => {
        this.showCollectionRecords = false
        this.showGame = false
        this.$toast('领取成功！')
        this.getData()
        this.$toast.clear()
      })
    },
    openedFun() {
      new Scratchcard({
        canvas: this.$refs.canvas,
        coverImg: scratch,
        // coverImg: 'https://img.chaolu.com.cn/ACT/anniversary-2023/lottery/fm.png',
        pixelRatio: 2,
        radius: 20,
        // coverText: '刮开赢免单 祝您好运！',
        drawCallback: () => {
          console.log('init')

        },
        doneCallback: () => {
          console.log('done')
          this.gameStatus = 'end'
        },
        slideCallback: () => {
          // 开始刮奖
          this.gameStatus = 'in';
          this.$axios.post(`${this.baseURLApp}/greatPromotion88/luckDraw`, {
            activityNo: this.itemActivityNo,
            token: this.token,
            userId: this.userId,
            serialNumber: this.serialNumber
          }).then(res => {
            const hit = res.data.hit;
            if (hit === 'NO_PARTICIPATE') {
              this.$toast('您没有刮奖次数了');
            } else if (hit === 'NOT_HIT') {
              this.awardInfo = {
                picUrl: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/anniversary2022/free/come_back/award0.png',
                prizeName: '无'
              }
            } else if (hit === 'HIT') {
              this.awardInfo = res.data;
            }
            this.getData()
          })
        }
      })
    },
    getData() {
      const ps = {
        activityNo,
        token: this.token,
        userId: this.userId,
        hasWx: true
      }
      console.log(ps);
      this.$axios.post(`${this.baseURLApp}/greatPromotion88/toBePerformPrizeDrawPage`, ps).then(res => {
        this.hasData = res.data.list && res.data.list.length;
        this.list = res.data.list.map(d => {
          return { ...d, payTime: d.payTime ? getFormatDate(d.payTime.replace(/-/g, "/"), 'MM-dd hh:mm:ss') : '--' }
        });
        this.count = res.data.drawNumber
      })
    },
    addressChange(obj) {
      console.log(obj, 'kkk');
      this.address = obj
    }
  },
  mounted() {

  },
  async created() {
    console.log(this.$route.query);
    this.serialNumber = '';
    initBack();
    await this.$getUserId();
    wx.miniProgram.postMessage({
      data: {
        type: 'share',
        shareImage: this.shareParams.miniImage,
        shareTitle: this.shareParams.title,
        shareUrl: this.shareParams.path,
      },
    })
    this.getData();
  }
}
</script>
<style lang="less">
.page-anniversary_lottery {
  background: #76B91F;
  position: absolute;
  height: 100vh;
  top: 0;
  // bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 0 32px;
  -webkit-overflow-scrolling: touch;

  div {
    box-sizing: border-box;
  }

  img {
    vertical-align: top;
    width: 100%;
  }

  .basebg {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.2);
    padding: 24px;
    margin-bottom: 90px;
    position: relative;
  }

  .pdt {
    padding-top: 196px;
    min-height: 454px;
  }

  .headbg {
    width: 566px;
    height: 152px;
    background: url(https://img.chaolu.com.cn/ACT/spring2024/headbg.png);
    background-size: 100% 100%;
    color: #fff;
    margin: 27px auto 0;
    padding-top: 37px;
    position: relative;

    .line1 {
      width: 300px;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      margin: 0 auto;
      overflow: hidden;
      line-height: 1;
    }

    .line2 {
      margin-top: 40px;
      text-align: center;
      color: #fff;
      font-size: 22px;
    }
  }

  .banner-rule {
    height: 166px;
    border-radius: 20px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .tips {
    color: #fff;
    font-size: 22px;
    line-height: 1;
    margin: 4px 0 28px;
    text-align: center;
  }

  .nochance {
    height: 266px;
    background: linear-gradient(180deg, #FFFEFF 0%, #F9FFD6 100%);
    border-radius: 12px;
    font-size: 22px;
    color: #003110;
    text-align: center;
    font-weight: bold;
    padding-top: 46px;
    line-height: 1;

    .noneimg {
      width: 110px;
      height: 110px;
      margin-bottom: 27px;
    }
  }

  .goods-list {
    .goods-model {
      border-radius: 12px;
      margin-bottom: 20px;
      background: linear-gradient(90deg, #EAFF73 0%, #FFEE90 100%);
      &:last-of-type {
        margin-bottom: 0;
      }

      .sec1 {
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        line-height: 54px;
        padding: 0 25px;
        color: #003110;
      }

      .sec2 {
        display: flex;
        align-items: center;
        padding: 0 32px;
        border-radius: 12px;
        height: 166px;
        background: linear-gradient(0deg, #F9FFD6 0%, #FFFEFF 100%);

        .prizes-img {
          width: 110px;
          height: 110px;
          border-radius: 10px;
          margin-right: 24px;
          background: #f5f5f5;
          overflow: hidden;

          img {
            height: 100%;
            object-fit: cover;
          }

          .notgame {
            background: #E3F5CB;
            text-align: center;
            line-height: 110px;
            color: #003110;
            font-size: 24px;
            font-weight: bold;
          }
        }

        .prizes-info {
          margin-right: 20px;
          overflow: hidden;

          .line1 {
            height: 40px;
            background: linear-gradient(90deg, #3F8C00 0%, #71AE3F 100%);
            border-radius: 8px;
            font-size: 24px;
            color: #fff;
            padding: 0 12px;
            line-height: 42px;
            display: inline-block;
            max-width: 100%;
            margin-bottom: 17px;
          }

          .line2 {
            color: #003110;
            font-size: 22px;
            font-weight: bold;
            line-height: 1;
          }
        }

        .rb {
          width: 150px;
          text-align: right;

          .button {
            width: 150px;
            height: 60px;
          }

          .end {
            width: 102px;
            height: 102px;
          }
        }
      }
    }
  }

  .ci {
    width: 360px;
    height: 93px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -45%);
    background: url(https://img.chaolu.com.cn/ACT/spring2024/rewardbg.png);
    background-size: 100% 100%;
  }

  .bd {
    background: #fff;
    padding: 52px 34px 28px;
    // min-height: 1554px;
    border-radius: 12px;

    .tips2 {
      font-size: 22px;
      color: #003110;
      text-align: center;
      line-height: 1;
      margin-bottom: 22px;
    }
  }

  .rule {
    border-radius: 12px;
    background: linear-gradient(0deg, #F9FFD6 0%, #FFFEFF 100%);
    padding: 60px 32px 40px;


    .rule-detail {
      line-height: 1.6;
      font-size: 22px;
      color: #003110;

      div {
        margin-bottom: 30px;

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }

  .sm {
    margin-bottom: 44px;
  }

  .body-end {

    text-align: center;
    color: #fff;
    font-size: 22px;
    position: relative;
    padding-bottom: 80px;

    &::after {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
      margin-left: 29px;
      margin-top: -2px;
    }

    &::before {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
      margin-right: 29px;
      margin-top: -2px;
    }

  }

  .pop {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 0 0 56px;

    .pop-title {
      color: #622009;
      font-weight: bold;
      background: linear-gradient(180deg, #ffeae9 0%, #FFFFFF 100%);
      font-size: 36px;
      text-align: center;
      height: 120px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .ts {
        position: relative;
        transform-style: preserve-3d;
        display: inline-block;

        &::after {
          content: '';
          position: absolute;
          left: -6px;
          bottom: 0px;
          width: 154px;
          height: 18px;
          background: linear-gradient(90deg, #fbbaa3 0%, rgba(195, 254, 34, 0) 100%);
          border-radius: 38px;
          transform: translateZ(-10px);
          z-index: -1;
        }
      }

      .pop-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
        font-size: 28px;
        text-align: center;
      }
    }
  }

  .collection-records {
    .mode1 {
      font-size: 28px;
      color: #003110;
      text-align: center;
      padding: 32px 0;
      line-height: 44px;
    }

    .mode2 {
      display: flex;
      align-items: center;
      padding: 40px 32px 56px;
      position: relative;

      &::after {
        content: '';
        height: 4px;
        background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/border.png);
        background-size: cover;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .maplogo {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }

      .line1 {
        font-size: 24px;
        color: #666;
      }

      .line2 {
        color: #242831;
        font-size: 30px;
        font-weight: bold;
        margin: 20px 0;
      }

      .line3 {
        color: #242831;
        font-size: 26px;

        .phone {
          color: #666;
        }
      }
    }

    .jg {
      height: 16px;
      background: #F5F5F5;
    }

    .records-box {
      min-height: 520px;
    }

    .records-model {
      display: flex;
      padding: 48px 32px 0;

      .records-img {
        width: 192px;
        height: 192px;
        margin-right: 32px;
        border-radius: 8px;
        overflow: hidden;
      }

      .records-name {
        font-size: 28px;
        font-weight: bold;
        color: #242831;
        margin-bottom: 40px;
      }

      .records-size {
        font-size: 24px;
        color: #242831;
        line-height: 1;
        margin-bottom: 20px;
      }

      .records-tips {
        font-size: 22px;
        color: #666;
        line-height: 34px;
      }
    }
  }

  .popgame {
    border-radius: 24px 24px 0px 0px;
    background: linear-gradient(0deg, #FFFEFF 0%, #FFEAE9 100%);

    .pop-head {
      height: 130px;
      // background: linear-gradient(0deg, #FFFEFF 0%, #FFEAE9 100%);
      line-height: 130px;
      position: relative;
      text-align: center;
      color: #452C29;
      font-weight: bold;

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
        font-size: 28px;
        text-align: center;
      }
    }

    .popup-box {
      padding: 0 0 56px;

      .canvas-box {
        width: 590px;
        height: 642px;
        margin: 0 auto;
        border-radius: 40px;
        background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/lottery/gamebg.png);
        background-size: 100% 100%;
        position: relative;
        padding-top: 102px;

        .game-title {
          font-size: 24px;
          color: #003110;
          text-align: center;
          line-height: 1;
          position: relative;

          &::after {
            content: '\25B2';
            //font: normal normal normal 14px vant-icon;
            position: absolute;
            left: 50%;
            top: 100%;
            font-size: 22px;
            transform: translate(-50%, 0) rotate(180deg);
          }
        }

        .canvas-bg {
          width: 430px;
          height: 430px;
          background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/lottery/cbg.png);
          background-size: 100% 100%;
          margin: 36px auto 0;
          text-align: center;
          position: relative;

          canvas {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            z-index: 2;
            position: relative;
          }

          .award-info {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            .f1 {
              color: #003110;
              font-size: 36px;
              font-weight: bold;
              line-height: 1;
              margin: 58px 0 21px;
            }

            .f2 {
              color: #003110;
              font-size: 32px;
              font-weight: bold;
              line-height: 1;
              margin-bottom: 17px;
              padding: 0 40px;
            }

            .f3 {
              width: 240px;
              height: 240px;
            }
          }

        }

        .text-tip {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          width: 100%;
          height: 100px;
          color: white;
          margin-bottom: 30px;
          text-align: center;
          line-height: 66px;
          font-size: 48px;

          div {
            width: 192px;
            height: 64px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;
          }

          .text-tip1 {
            background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/text-tip1.png");
          }

          .text-tip2 {
            background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/text-tip2.png");
          }

          span {
            font-weight: bold;
            font-size: 72px;
            color: #FFF040;
            margin: 0 16px;
          }
        }
      }


    }
  }



  .showEnd {
    visibility: visible !important;
  }

  .close-game {
    line-height: 92px;
    background: linear-gradient(0deg, #FF8E55 0%, #F4BC43 100%);
    border-radius: 8px;
    margin: 52px 32px 0;
    text-align: center;
    color: #fff;
    visibility: hidden;
  }

  .hide {
    display: none;
  }
}
</style>
