<template>
  <div :class="['container', appTypeStr]">
    <nav-bar header-title="超鹿双11 运动大富翁" backColor="#fff" :show-back="true" :header-back="newAppBack" ref="head" />
    <img src="https://img.chaolu.com.cn/ACT/double11-2024/title.png" alt="" class="game-title flex">
    <!-- 电玩机 -->
    <div class="game-bg col-start-center">
      <div class="map">
        <img v-for="(item, index) in gift" :key="index" :src="`https://img.chaolu.com.cn/ACT/double11-2024/${item.img}.png`" alt="" :class="['prize', `pos${index + 1}`, giftFly(item.location) ? 'fade' : '']" />
        <img src="https://img.chaolu.com.cn/ACT/double11-2024/deer.png" alt="" :class="['deer', `${all.userLocationIndex === 0 ? '' : 'ani'}`, `step${all.userLocationIndex}`, `${faceLeft ? 'left' : ''}`, `${isBounce ? (faceLeft ? 'bounceXL' : 'bounceX') : ''}`]" />
      </div>
      <img src="https://img.chaolu.com.cn/ACT/double11-2024/boli.png" alt="" class="boli">
      <img src="https://img.chaolu.com.cn/ACT/double11-2024/yaogan.png" alt="" class="yaogan">

      <div class="top-button col-start-center">
        <img @click="posterShare" src="https://img.chaolu.com.cn/ACT/double11-2024/share-btn.png" alt="" >
        <img @click="openRule" src="https://img.chaolu.com.cn/ACT/double11-2024/rule-btn.png" alt="" >
      </div>
    </div>
    <!-- 操作 -->
    <div class="action-box row-between-center">
      <img class="action1" @click="openExchange" src="https://img.chaolu.com.cn/ACT/double11-2024/action1.png" alt="" />
      <img class="action2" @click="gameStart" src="https://img.chaolu.com.cn/ACT/double11-2024/action3.png" alt="" />
      <img class="action1" @click="openTask" src="https://img.chaolu.com.cn/ACT/double11-2024/action2.png" alt="" />
    </div>
    <!-- 剩余 -->
    <div class="num-box row-between-center f26 fw6">
      <div @click="openExchange" class="num-item row-center-center">
        <img src="https://img.chaolu.com.cn/ACT/double11-2024/ssb1.png" alt="">
        运动币：{{ all.userCoinNum }}&nbsp;&nbsp;&nbsp;<van-icon name="arrow" size="16" />
      </div>
      <div @click="openCoinDetail" class="num-item row-center-center">
        <img src="https://img.chaolu.com.cn/ACT/double11-2024/ssb2.png" alt="">
        剩余次数：{{ all.userDiceNum }}次&nbsp;&nbsp;&nbsp;<van-icon name="arrow" size="16" />
      </div>
    </div>


    <!-- 骰子 弹窗 -->
    <div v-show="isDiceShow" :class="['bowl-container', isDiceShow ? 'fade' : '']">
      <div class="bowl inline" id="bowl">
        <div class="dice dice6 dice-position2"></div>
      </div>
    </div>

    <!-- 奖品 -->
    <van-popup v-model="isDrawResultShow" class="pop-result" :overlay="true" style="background-color: transparent; overflow: visible" :close-on-click-overlay="true">
      <div class="result col-start-center">
        <div class="title f52 puhui row-center-center" :data-content="`${all.userLocationIndex ? '恭喜您获得' : '恭喜本轮完成'}`">{{ all.userLocationIndex ? '恭喜您获得' : '恭喜本轮完成' }}</div>
        <img src="https://img.chaolu.com.cn/ACT/double11-2024/coin.png" alt="" />
        <p class="p1 puhui f48">{{ all.coinNum }}运动币</p>
        <p class="p2 f24">{{ all.userLocationIndex ? '系统已自动发放至您的账户' : '系统已自动发放至您的账户，即将开启新一轮游戏！' }}</p>

        <div class="btns f32 puhui row-center-center">
          <div @click="openExchange" class="btn btn1 row-center-center">去兑换</div>
          <div @click="gameStart" class="btn btn2 row-center-center">再来一次</div>
        </div>
      </div>
      <img @click="close" class="result-close" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/mid-autumn2021/result-close.png" alt="" />
    </van-popup>

    <!-- 没有抽奖机会 -->
    <van-popup v-model="isCountNoneShow" class="pop-result" :overlay="true" style="background-color: transparent; overflow: visible" :close-on-click-overlay="true">
      <div class="result type-2 col-start-center">
        <p class="p1 puhui f48">您没有骰子次数啦!</p>
        <p class="p2 f24">快去完成任务赚取更多次数吧~</p>

        <div class="btns f32 puhui row-center-center">
          <div @click="close" class="btn btn1 row-center-center">再考虑下</div>
          <div @click="openTask" class="btn btn2 row-center-center">去做任务</div>
        </div>
      </div>
    </van-popup>

    <!-- 分享海报 -->
    <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false" @share-success="shareSuccess" :shareItem="shareItem" :miniSharePoster="appTypeStr === 'mini'" :reload="true">
      <template slot="createDom">
        <div class="canvascss">
          <img src="https://img.chaolu.com.cn/ACT/double11-2024/poster.png" class="canvascss_bg" />
          <div class="canvascss_left">
            <img :src="userDetail.headImg" />
          </div>
          <div class="canvascss_name ellipsis">
            {{ userDetail.nickName }}
          </div>
          <div class="canvascss_right">
            <img v-if="userDetail.qrCode" :src="'data:image/png;base64,' + userDetail.qrCode" />
          </div>
        </div>
      </template>
    </common-share>
    <!-- 规则弹窗 -->
    <rule ref="rule"></rule>
    <!-- 任务弹窗 -->
    <task ref="task" @share="posterShare"></task>
    <!-- 任务弹窗 -->
    <coin-detail type="act" ref="coinDetail"></coin-detail>
  </div>
</template>
<script>
import { newAppBack, initBack, appOpenWeb } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import userMixin from '@/mixin/userMixin'
import Game from './components/game'
import commonShare from '@/components/commonShare/newShare'
import task from './components/task.vue'
import rule from './components/rule.vue'
import coinDetail from './components/coin-detail.vue'

import wx from 'weixin-js-sdk'

const activityNo = '2024double11'
const webUrl = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/double11-2024/index`)

export default {
  components: {
    navBar,
    commonShare,
    task,
    rule,
    coinDetail
  },
  mixins: [userMixin],
  data() {
    return {
      params: {},
      temporary: {},
      all: {userDiceNum: 0}, // 所有东西集合
      coinNum: 0,

      canMoveStep: 0,
      isBounce: false,
      timer: null,
      timer2: null,

      isDrawReady: true,
      isDrawResultShow: false,
      isCountNoneShow: false,
      isDiceShow: false,

      isShareShow: false,
      userDetail: {},
      shareParams: {
        title: '运动抵现金！超鹿双11大富翁~',
        miniImage: 'https://img.chaolu.com.cn/ACT/double11-2024/mini.png',
        path: `/pages/webView/index?webUrl=${webUrl}&userId=1`,
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      shareItem: ['minifriend', 'postermessage', 'save'],
      gift: [
        { img: 'p1', location: 3 },
        { img: 'p2', location: 6 },
        { img: 'p1', location: 8 },
        { img: 'p3', location: 11 },
        { img: 'p2', location: 15 },
        { img: 'p1', location: 18 },
        { img: 'p2', location: 21 },
        { img: 'p3', location: 24 },
        { img: 'p1', location: 27 },
      ],
    }
  },
  computed: {
    faceLeft() {
      if ([9, 10, 11, 12, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29].includes(this.all.userLocationIndex)) return true
      return false
    },
    giftFly() {
      return (location) => {
        return this.all.userLocationIndex === this.temporary.userLocationIndex && location === this.all.userLocationIndex
      }
    },
  },
  async created() {
    initBack()
    await this.$getUserId()

    this.params = {
      activityNo,
      userId: this.userId,
      token: this.token,
    }

    this.addGameChance('enter')
    this.initQr()

    if (this.appTypeStr === 'mini') {
      this.countPoint('409', '409', '40057')
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    } else {
      this.countPoint('409', '409', '40056')
    }

    const arr = [
      'https://img.chaolu.com.cn/ACT/double11-2024/dice1-c.png',
      'https://img.chaolu.com.cn/ACT/double11-2024/dice6-c.png',
      'https://img.chaolu.com.cn/ACT/double11-2024/dice2-c.png',
      'https://img.chaolu.com.cn/ACT/double11-2024/dice3-c.png',
      'https://img.chaolu.com.cn/ACT/double11-2024/dice4-c.png',
      'https://img.chaolu.com.cn/ACT/double11-2024/dice5-c.png'
    ]
    for (let i = 0; i < arr.length; i++) {
      let img = new Image()
      img.src = arr[i]
      img.onload = function () {
        console.log('图片缓存完成')
      }
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.getInit()
      }
    })
  },
  methods: {
    newAppBack,
    move() {
      clearTimeout(this.timer)
      clearTimeout(this.timer2)

      if (this.canMoveStep <= 0) {
        this.isBounce = false
        // 行动结束后 才将结果赋给最新值
        this.all = JSON.parse(JSON.stringify(this.temporary))
        this.all.userLocationIndex = this.all.userLocationIndex === 29 ? 0 : this.all.userLocationIndex
        this.isDrawReady = true
        if (this.temporary.isReward) {
          setTimeout(() => {
            this.isDrawResultShow = true
          }, 500)
        }

        return
      }

      this.isBounce = false

      this.timer2 = setTimeout(() => {
        this.all.userLocationIndex++
        this.canMoveStep--
        this.isBounce = true
      }, 200)
      this.timer = setTimeout(() => {
        this.move()
      }, 500)
    },
    close() {
      this.isDrawResultShow = false
      this.isCountNoneShow = false
    },
    gameStart() {
      this.close()

      if (this.all.userDiceNum <= 0) {
        this.isCountNoneShow = true
        return
      }

      if (this.isDrawReady) {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          loadingType: 'spinner',
        })

        this.isDrawReady = false
        this.$axios
          .post(this.baseURLApp + '/double11/2024/rollingTheDice', this.params)
          .then((res) => {
            this.isDiceShow = true
            this.$toast.clear()
            // 可走步数
            this.temporary = res.data
            const lastIndex = res.data.userLocationIndex === 0 ? 29 : res.data.userLocationIndex
            this.canMoveStep = lastIndex - this.all.userLocationIndex

            // 摇骰子
            Game.start([res.data.dicePoint])

            setTimeout(() => {
              $('#bowl').removeClass('active')
              this.isDiceShow = false
            }, 2500)
            setTimeout(() => {
              this.move()
            }, 3100)
          })
          .catch((res) => {
            setTimeout(() => {
              this.isDrawReady = true
            }, 2500)
          })
      }
    },
    getInit() {
      this.$axios.post(this.baseURLApp + '/double11/2024/getBoardGameUserData', this.params).then((res) => {
        this.temporary = res.data
        this.all = res.data
        this.all.userLocationIndex = this.all.userLocationIndex === 29 ? 0 : this.all.userLocationIndex
      })
    },

    addGameChance(type) {
      const interFace = type === 'enter' ? 'loginPage' : 'shareLink'

      return this.$axios
        .post(`${this.baseURLApp}/double11/2024/diceTask/${interFace}`, {
          userId: this.userId,
          token: this.token
        }).then((res) => {
          this.getInit()
        })
    },
    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: activityNo,
            webUrl: `${window.location.origin}/#/superdeer-activity/double11-2024/index`,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        })
    },
    posterShare() {
      this.isShareShow = true
      if (this.appTypeStr === 'mini') {
        this.addGameChance('share')
      }
    },
    openRule() {
      this.$refs.rule.showFun()
    },
    openCoinDetail() {
      this.getInit()
      this.$refs.coinDetail.showFun()
    },
    openExchange() {
      appOpenWeb('', `${window.location.origin}/#/superdeer-activity/double11-2024/exchange`)
    },
    openTask() {
      this.isCountNoneShow = false
      this.$refs.task.showFun()
      this.getInit()
    },
    shareSuccess(key) {
      this.isShareShow = false
      if (key !== 'save') {
        this.addGameChance('share')
      }
    }
  },
}
</script>
<style lang="less" scoped>
@import './components/game';
.container {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 80px 0 84px;
  background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/bg.png);
  background-size: cover;
  background-position: center;
  &.mini {
    padding: 0 0 84px;
  }
}
* {
  box-sizing: border-box;
}

.top-button {
  position: absolute;
  right: 35px;
  top: 371px;
  z-index: 99;
  img {
    width: 42px;
    margin: 0 0 40px;
  }
}
.game-title {
  width: 530px;
  margin: 0 0 0 115px;
}
.game-bg {
  background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/game-bg.png);
  width: 100%;
  height: 1047px;
  background-size: 100% 100%;
  position: relative;
  .map {
    margin: 196px auto 0;
    background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/map.png);
    width: 520px;
    height: 707px;
    background-size: 100% 100%;
    position: relative;

  }
  .boli {
    width: 533px;
    position: absolute;
    left: 109px;
    top: 189px;
    z-index: 3;
  }
  .yaogan {
    width: 99px;
    position: absolute;
    left: 446px;
    top: 865px;
    z-index: 4;
  }
  .prize {
    width: 100px;
    position: absolute;
    &.pos1 {
      left: 65px;
      bottom: 258px;
    }
    &.pos3 {
      left: 264px;
      bottom: 450px;
    }
    &.pos6 {
      left: 401px;
      bottom: 142px;
    }
    &.pos9 {
      left: 193px;
      bottom: 594px;
    }
    &.pos2 {
      left: 128px;
      bottom: 445px;
    }
    &.pos5 {
      left: 263px;
      bottom: 72px;
    }
    &.pos7 {
      left: 401px;
      bottom: 365px;
    }
    &.pos4 {
      left: 267px;
      bottom: 211px;
    }
    &.pos8 {
      left: 400px;
      bottom: 597px;
    }
    &.fade {
      animation: slide-out-blurred-top 0.7s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
    }
  }
  .deer {
    width: 67px;
    position: absolute;

    transform-origin: center;
    z-index: 2;
    left: 122px;
    bottom: 53px;
    &.ani {
      transition: all 0.3s ease-out;
    }
    &.step1 {
      left: 80px;
      bottom: 126px;
    }
    &.step2 {
      left: 80px;
      bottom: 196px;
    }
    &.step3 {
      left: 80px;
      bottom: 266px;
    }
    &.step4 {
      left: 80px;
      bottom: 366px;
    }
    &.step5 {
      left: 80px;
      bottom: 436px;
    }
    &.step6 {
      left: 144px;
      bottom: 456px;
    }
    &.step7 {
      left: 206px;
      bottom: 456px;
    }
    &.step8 {
      left: 276px;
      bottom: 456px;
    }
    &.step9 {
      left: 276px;
      bottom: 366px;
    }
    &.step10 {
      left: 276px;
      bottom: 314px;
    }
    &.step11 {
      left: 276px;
      bottom: 224px;
    }
    &.step12 {
      left: 206px;
      bottom: 216px;
    }
    &.step13 {
      left: 206px;
      bottom: 146px;
    }
    &.step14 {
      left: 206px;
      bottom: 76px;
    }
    &.step15 {
      left: 275px;
      bottom: 76px;
    }
    &.step16 {
      left: 346px;
      bottom: 72px;
    }
    &.step17 {
      left: 416px;
      bottom: 72px;
    }
    &.step18 {
      left: 418px;
      bottom: 148px;
    }
    &.step19 {
      left: 418px;
      bottom: 240px;
    }
    &.step20 {
      left: 416px;
      bottom: 304px;
    }
    &.step21 {
      left: 416px;
      bottom: 378px;
    }
    &.step22 {
      left: 416px;
      bottom: 470px;
    }
    &.step23 {
      left: 416px;
      bottom: 530px;
    }
    &.step24 {
      left: 416px;
      bottom: 604px;
    }
    &.step25 {
      left: 336px;
      bottom: 598px;
    }
    &.step26 {
      left: 268px;
      bottom: 598px;
    }
    &.step27 {
      left: 206px;
      bottom: 602px;
    }
    &.step28 {
      left: 134px;
      bottom: 598px;
    }
    &.step29 {
      left: 34px;
      bottom: 598px;
    }
    &.left {
      transform: rotateY(180deg);
    }

    &.bounceX {
      animation: bounceX 0.3s linear;
    }
    &.bounceXL {
      animation: bounceXL 0.3s linear;
    }
  }
}
@-webkit-keyframes fade {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}


@keyframes heartbeat {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  15% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  20% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  25% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1);
  }
  32.5% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  37.5% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

.bowl-container {
  &.fade {
    animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  }
}
@keyframes bounceX {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes bounceXL {
  0% {
    transform: translateY(0px) rotateY(180deg);
  }
  50% {
    transform: translateY(-25px) rotateY(180deg);
  }
  100% {
    transform: translateY(0px) rotateY(180deg);
  }
}

@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.pop-result {
  .result {
    width: 631px;
    height: 718px;
    background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/a-bg.png);
    background-size: 100% 100%;
    position: relative;
    &.type-2 {
      width: 620px;
      height: 473px;
      background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/a-bg.png);
      padding: 102px 0 0;
    }
    .title {
      width: 386px;
      height: 100px;
      background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/a-title.png);
      background-size: 100% 100%;
      position: absolute;
      left: 122px;
      top: -21px;

      padding: 0 0 10px;
      text-stroke: 4px #b84500;
      -webkit-text-stroke: 4px #b84500;
    }
    [data-content]::before {
      content: attr(data-content);
      position: absolute;
      -webkit-text-stroke: 0;
      color: #fff;
    }
    img {
      width: 230px;
      margin: 90px 0 0;
    }
    .p1 {
      color: #402828;
      margin: 10px 0 0;
    }
    .p2 {
      margin: 16px 0 0;
      padding: 0 120px;
      text-align: center;
    }
    .btns {
      position: absolute;
      bottom: 110px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      .btn {
        width: 220px;
        height: 93px;
        padding: 0 0 10px;
        margin: 0 9px;
        background-size: 100% 100%;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        &.btn1 {
          background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/btn1.png);
        }
        &.btn2 {
          background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/btn2.png);
        }
      }
    }
  }
  .result-close {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    display: block;
  }
}
.action-box {
  padding: 0 40px;
  margin: -20px 0 0;
  position: relative;
  .action2 {
    width: 285px;
    animation: heartbeat 2s infinite;
  }
  .action1 {
    width: 166px;
  }
}
.num-box {
  margin: 40px auto;
  padding: 0 55px;
  .num-item {
    width: fit-content;
    padding: 0 12px 0 68px;
    height: 56px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 28px;
    color: #fff;
    position: relative;
    img {
      width: 87px;
      position: absolute;
      bottom: -7px;
      left: -22px;
    }
  }
}
.pop-radius {
  padding: 44px 32px;
  border-radius: 24px 24px 0px 0px;
  background: linear-gradient(180deg, #fff 40px, #fff4ed 180px, #fff4ed 100%);

  .record-title {
    text-align: center;
    width: fit-content;
    border-radius: 9px;
    margin: 0 auto;
    padding: 0 0 4px;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 18px;
      border-radius: 9px;
      background: linear-gradient(270deg, rgba(255, 232, 206, 0) 0%, #fa6b76 100%);
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  /deep/.van-popup__close-icon--top-right {
    top: 44px;
  }

  .empty {
    color: #e22134;
    margin: 12vh 0;
    text-align: center;
  }
}
@bei: 1px;

.canvascss {
  position: fixed;
  bottom: -200%;
  // top: 0;
  width: @bei*600;
  height: @bei*1066;
  margin: 0 auto;
  box-sizing: border-box;

  .canvascss_bg {
    width: 100%;
    height: 100%;
  }

  .canvascss_left {
    position: absolute;
    left: @bei*26;
    bottom: @bei*46;
    z-index: 1;
    width: @bei*100;
    height: @bei*100;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .canvascss_name {
    position: absolute;
    left: @bei*150;
    bottom: @bei*114;
    z-index: 1;
    width: @bei*252;
  }

  .canvascss_right {
    position: absolute;
    right: @bei*34;
    bottom: @bei*28;
    width: @bei*124;
    height: @bei*124;
    overflow: hidden;
    //background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/border.png);
    background-size: 100% 100%;
    padding: @bei*12;

    img {
      border-radius: @bei*8;
      width: 100%;
      height: 100%;
    }
  }
}

/deep/.canvas-box {
  padding: 20px 40px !important;
  box-sizing: border-box;

  .create-img {
    width: 100% !important;
  }
}
</style>
